<template>
  <div id="dashboard">
    <Hero/>
    <div class="dashboard-section">
      <div class="header-section">
        <h1>Welcome!</h1>
        <button class="logout" v-on:click="logout()">Logout > </button>
      </div>
      <v-data-table
        :headers="headers"
        :items="reports"
        :items-per-page="25"
        :key="refreshKey"
        class="elevation-1"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="editReport(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            @click="deleteReport(item)"
          >
            mdi-delete
          </v-icon>
        </template>
          
      </v-data-table>
      <v-btn
        depressed
        elevation="2"
        outlined
        rounded
      >
      <router-link class="see-more" to="/report/new">Create Report</router-link></v-btn>
     </div> 
  </div>
</template>

<script>
import api from '@/api'
import Hero from '@/components/Hero.vue'
export default {
  components: { 
    Hero
  },
  async created () {
    const getReports = await api.get('/reports')
    this.reports = getReports.reports
    this.rows = this.reports.map(r => [r.date,r.location_name,r.number_of_anglers,r.report_id])
    this.refreshKey += 1
  },
  data() {
    return {
      headers: [
        {
          text: 'Date',
          align: 'start',
          value: 'date',
        },
        { text: 'Location', value: 'location_name' },
        { text: 'Anglers', value: 'number_of_anglers' },
        { text: 'Rating', value: 'rating' },
        { text: '', value: 'actions', sortable: false },
      ],
      refreshKey: 0,
      reports: [],
      rows: []
    }
  }, 
  methods: {
    async getReports() {
      const getReports = await api.get('/reports')
      this.reports = getReports.reports
      this.rows = this.reports.map(r => [r.date,r.location_name,r.number_of_anglers,r.report_id])
      this.refreshKey += 1
    },
    editReport(value) {
        console.log(value);
    },
    deleteReport(value) {
      api.delete(`/reports/${value.report_id}`, this.form).then(() => this.getReports())
    },
    logout() {
      this.$cognitoAuth.logout();
      this.$router.push('/');
    }
  }
}
</script>


<style lang="scss">
#dashboard {
  .dashboard-section {
    width: 65%;
    margin: auto;
    margin-bottom: 50px;
    .header-section {
      margin: 50px 0;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    article {
      margin-top: 30px;
    }
    .table-action {
      border: none;
      outline: none;
      color: #3C6E71;
      font-weight: 600;
      background-color: white;
    }
    .table-action:hover {
      cursor: pointer;
    }
    .logout {
      color: #3C6E71;
      font-weight: 600;
      font-size: 18px;
    }
  }
  @media only screen and (max-width: 500px) {
    .dashboard-section {
      width: 90%;
      margin: auto;
    }
  }
}
</style>